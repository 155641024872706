const meterageSpreadInfo = {
  sheetName: '绑定草图',
  columns: [
    {
      label: '编号',
      dataKey: 'a15Guid',
      width: '0'
    },
    {
      label: '子目号',
      dataKey: 'meterageDisplayCode',
      width: '110'
    },
    {
      label: '子目名称',
      dataKey: 'meterageName',
      width: '150'
    },
    {
      label: '单位',
      dataKey: 'unit',
      width: '60'
    },
    {
      label: '合同数量',
      dataKey: 'num',
      width: '100'
    },
    {
      label: '合同单价',
      dataKey: 'price',
      width: '100'
    },
    {
      label: '合同金额',
      dataKey: 'amount',
      width: '100'
    },
    {
      label: '部位',
      dataKey: 'part',
      width: '200'
    },
    {
      label: '起始桩号',
      dataKey: 'startMileage',
      width: '100'
    },
    {
      label: '终止桩号',
      dataKey: 'endMileage',
      width: '100'
    },
    {
      label: '计算公式',
      dataKey: 'formula',
      width: '100'
    },
    {
      label: '申报数量',
      dataKey: 'declareNum',
      width: 100
    },
    {
      label: '草图名称',
      dataKey: 'pictureName',
      editable: true,
      width: '100'
    },
    {
      label: '草图编号',
      dataKey: 'pictureGuid',
      editable: true,
      width: '260'
    }
  ]
}

export default meterageSpreadInfo
