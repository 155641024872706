<template>
  <div class="bind-picture">
    <div class="excel">
      <my-search-bar>
        <el-form :inline="true">
          <el-form-item>
            <el-button type="primary" @click="handleSave">保存</el-button>
          </el-form-item>
        </el-form>
      </my-search-bar>
      <my-spread ref="mySpread"
        v-if="defaultDataList.length"
        :default-data-list="defaultDataList"
        :spread-info="spreadInfo">
      </my-spread>
    </div>
    <div class="picture">
      <picture-index ref="pictureIndex" :selection="false" @checkPicture="handleCheckPicture"></picture-index>
    </div>
  </div>
</template>

<script>
import PictureIndex from '@/views/PictureManage/PictureIndex'
import SpreadInfo from '@/views/ExaminationManage/meterageSpreadInfo'
import MySpread from '@/components/Spread/MySpread'
import a15Service from '@/services/a15Service'
import PageInfo from '@/support/PageInfo'

export default {
  name: 'BindPicture',
  components: {
    PictureIndex,
    MySpread
  },
  data () {
    return {
      examinationGuid: this.$route.query['examinationGuid'],
      spreadInfo: JSON.parse(JSON.stringify(SpreadInfo)),
      defaultDataList: [],
      pageSize: 100000,
      pageIndex: 1,
      currentRowIndex: -1
    }
  },
  methods: {
    handleFitColumn () {
      this.$refs.mySpread.fitColumn()
    },
    handleSave () {
      console.log(this.$refs.mySpread.getData())
      a15Service.batchAddPicture(this.$refs.mySpread.getData().map(item => {
        return {
          a15Guid: item.a15Guid,
          pictureGuid: item.pictureGuid
        }
      }))
        .then(res => {
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: '操作失败！'
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败！'
          })
        })
    },
    handleCheckPicture (picture) {
      this.currentRowIndex = this.$refs.mySpread.getActiveRowIndex()
      this.$refs.mySpread.setValue(this.currentRowIndex, 'pictureGuid', picture.pictureGuid)
      this.$refs.mySpread.setValue(this.currentRowIndex, 'pictureName', picture.pictureName)
    },
    getA15s () {
      const searchModel = {
        examinationGuid: this.examinationGuid
      }
      a15Service.list(new PageInfo({ pageSize: this.pageSize, pageIndex: this.pageIndex, fieldOrder: 'MeterageLists.sortId,A15s.StartMileage,A15s.EndMileage,A15s.Part asc' }), searchModel)
        .then(res => {
          this.$loading().close()
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            this.defaultDataList = res.data.data.map(item => {
              if (item.children && item.children.length) {
                item.declareNum = item.children[item.children.length - 1].num
              }
              return item
            })
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$loading().close()
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    }
  },
  mounted () {
    this.$myLoading()
    this.getA15s()
  }
}
</script>

<style scoped lang="scss">
.bind-picture {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 280px;
  gap: 10px;
}
.excel {
  display: grid;
  grid-template-rows: 50px 1fr;
  height: 100%;
}
.picture {
  height: 100%;
  overflow: scroll;
}
</style>
